<template>
  <div class="container">
    <div class="row">
      <div class="col-3 text-center" v-for="nft in nfts" :key="nft.id">
        <!--router-link v-bind:to="'/collectible/' + nft.id">
          <img v-bind:src="nft.image" />
        </router-link-->
        <img v-bind:src="nft.image" /><br/>
        Owner: <MetamaskAddress v-bind:address="nft.owner" short="yes"></MetamaskAddress>
        <!--MarketV3 brief="yes" v-if="nft.owner" v-bind:owner="nft.owner" ca="0x1De097da3Fe906137ece38a0583dA427b99e566e" v-bind:id="nft.id"></MarketV3-->
      </div>
    </div>
  </div>
</template>

<script>
import MetamaskAddress from "../components/MetamaskAddress";
//import MarketV3 from "../components/Rat/MarketV3";
import {ethers} from "ethers";
import nftInfo from "../../artifacts/contracts/RatCollectibles.sol/NFT.json";
export default {
  components: {
    //MarketV3,
    MetamaskAddress
  },
  data() {
    return {
      nfts: [],
    }
  },
  watch: {
    '$store.state.provider'() {
        this.loadCollectibles();
    }
  },
  mounted() {
    this.loadCollectibles();
  },
  methods: {
    loadCollectibles: function () {
      if (this.$store.state.provider) {
        let nftContract = new ethers.Contract('0x1De097da3Fe906137ece38a0583dA427b99e566e', nftInfo.abi, this.$store.state.provider)
        for (let nft = 1; nft <= 23; nft++) {
          nftContract.ownerOf(nft).then((owner) => {
            this.nfts.push({
              id: nft,
              owner: owner,
              image: 'https://raw.githubusercontent.com/shadowkite/rat-collectibles/main/images/' + nft + '.webp'
            });
          });
        }
      }
    }
  }
}
</script>